/* src/Components/admin/BackupSessions.css */

.backup-sessions {
    margin: 1rem;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .backup-sessions h2 {
    margin-bottom: 1rem;
  }
  
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
  }
  
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
  }
  
  .p-datatable .p-paginator {
    padding: 1rem;
  }
  