/* Example: DBViewer.css */

/* 
  Overall layout: 
    - (Left) Sidebar
    - (Middle) Table List
    - (Right) Main content 
*/
.dbviewer-wrapper {
    display: flex;
    height: 100vh; /* Occupies full viewport height */
    color: #333; 
    font-family: 'Open Sans', sans-serif;
  }
  
  /* Sidebar area (assuming you keep its styling mostly the same) */
  .dbviewer-sidebar {
    width: 285px;
    background-color: #1d1d1d;
  }
  
  /* Middle panel for the table list (the Tree of tables) */
  .dbviewer-tablelist {
    width: 300px;
    background-color: #f8f8f8;
    padding: 1rem;
    overflow-y: auto;
    border-right: 1px solid #ccc;
  }
  
  .dbviewer-tablelist h3 {
    margin-top: 0; 
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  /* Remove default borders from the Tree, if you want a cleaner look */
  .dbviewer-tablelist .p-tree .p-tree-container {
    border: none;
    background: transparent;
  }
  
  /* Right panel: main content area (DataTable, skeleton, etc.) */
  .dbviewer-main {
    flex: 1; /* Grows to fill remaining space */
    padding: 1rem;
    overflow-y: auto;
    background-color: #fff;
  }
  
  /* Show skeleton lines with some spacing and consistent sizing */
  .dbviewer-skeleton .p-skeleton {
    margin-bottom: 1rem;
    border-radius: 4px;
  }
  
  /* 
    Search bar container: we want the icon on top of the input 
    in a more "overlaid" style. 
  */
  .dbviewer-search {
    position: relative; /* So we can absolutely position the icon */
    display: inline-block;
    margin-left: auto; /* pushes the search to the right in a flex container if needed */
  }
  
  .dbviewer-search .p-inputtext {
    padding-left: 2.5rem !important; /* reserve space for the icon */
  }
  
  .dbviewer-search .pi-search {
    position: absolute;
    left: 0.8rem;    /* tweak to position the icon horizontally */
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    pointer-events: none; /* make sure clicks focus the input, not the icon */
    font-size: 1.2rem;
  }
  
  /* DataTable styling */
  .p-datatable {
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
  }
  
  /* Scrollable table adjustments (if needed) */
  .p-datatable-wrapper {
    max-height: 70vh; /* example: limit table height, scroll inside if it’s large */
    overflow: auto;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  /* Dialog styling (the overlay form) */
  .p-dialog .p-dialog-header {
    font-weight: bold;
  }
  
  .p-dialog .p-field label {
    font-weight: 600;
    display: block;
    margin-bottom: 0.25rem;
  }
  
  /* Optional: Enhance the dialog box shadow */
  .p-dialog {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
  }
  
  /* Buttons, inputs, etc. can be further styled as you like */
  