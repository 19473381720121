/* src/Components/admin/CloudConnectTenants.css */

.cloud-connect-tenants {
    margin: 1rem;
}

.cloud-connect-tenants h2 {
    margin-bottom: 1rem;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.tenant-details-table {
    width: 100%;
}

.tenant-details-table table {
    width: 100%;
    border-collapse: collapse;
}

.tenant-details-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.tenant-details-table td {
    padding: 0.5rem;
    vertical-align: top;
}

.label-cell {
    font-weight: bold;
    text-align: left;
    width: 40%;
}

.value-cell {
    text-align: left;
    width: 60%;
    word-wrap: break-word;
}
  