/* Register.module.css */

.container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  padding: 20px;
}

.registrationFormContainer {
  background-color: #ffffff;
  padding: 60px 40px 40px 40px; /* Increased top padding to accommodate logo */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 500px; /* Increased width to accommodate additional fields */
  position: relative;
  animation: fadeIn 0.5s ease-in-out;
}

.logoContainer {
  position: absolute;
  top: -40px; /* Adjusted to align better with increased padding */
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: 21%;
  padding: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.alert {
  padding: 15px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  position: relative;
  text-align: center;
  font-weight: 500;
  animation: slideDown 0.3s ease-out;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.danger {
  background-color: #f8d7da;
  color: #721c24;
}

.warning {
  background-color: #fff3cd;
  color: #856404;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 12px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: inherit;
}

.fieldset {
  border: none;
  padding: 0;
}

.legend {
  font-size: 1.8rem;
  margin-bottom: 30px;
  text-align: center;
  color: #333333;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  color: #555555;
  font-weight: 600;
}

.required {
  color: #e74c3c;
}

.input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.input:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.inputError {
  border-color: #e74c3c;
}

.errorMessage {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 5px;
}

.submitButton {
  width: 100%;
  background-color: #4a90e2;
  color: #ffffff;
  padding: 14px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #357ab8;
}

.registerLink {
  display: block;
  text-align: center;
  color: #4a90e2;
  text-decoration: none;
  margin-top: 15px;
  font-size: 14px;
  transition: color 0.3s ease;
}

.registerLink:hover {
  color: #357ab8;
}

/* New Styles for Name Fields */
.nameFields {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between first and last name fields */
}

.nameFields .formGroup {
  flex: 1;
}

/* Password Wrapper and Toggle */
.passwordWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.togglePassword {
  position: absolute;
  right: 16px;
  cursor: pointer;
  color: #4a90e2;
  font-weight: 500;
  user-select: none;
}

.togglePassword:hover {
  color: #357ab8;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .nameFields {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .registrationFormContainer {
    padding: 40px 20px 20px 20px; /* Adjusted for smaller screens */
    max-width: 100%; /* Allow full width on very small screens */
  }

  .legend {
    font-size: 1.5rem;
  }

  .logo {
    width: 60px;
    height: 60px;
  }

  .submitButton {
    padding: 12px 0;
    font-size: 14px;
  }

  .registerLink {
    font-size: 13px;
  }

  .alert {
    padding: 12px 16px;
    font-size: 14px;
  }

  /* Adjust name fields on small screens */
  .nameFields {
    flex-direction: column;
    gap: 10px;
  }
}
