/* src/Components/UserAdministration.css */

/* Overall Layout */
.datatable-crud-demo .card {
    background: #fff;
    border-radius: 4px;
    padding: 1rem;
    box-shadow: none; /* Remove default card shadow if desired */
}

.datatable-crud-demo h4 {
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #333;
}

.datatable-crud-demo p {
    margin-bottom: 1.5rem;
    color: #555;
    font-size: 0.95rem;
}

/* Toolbar styling */
.p-mb-4 {
    margin-bottom: 1rem !important;
}

/* Table Styling */
.p-datatable {
    border: 1px solid #ddd;
    border-radius: 4px;
}

.p-datatable .p-datatable-header, 
.p-datatable .p-datatable-footer {
    background: #f1f1f1;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #ddd;
    color: #333;
}

.p-datatable-thead > tr > th {
    background: #f8f8f8;
    text-align: left;
    color: #333;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
}

.p-datatable tbody tr:nth-child(even) {
    background: #fafafa;
}

/* Tags for roles */
.p-tag {
    border-radius: 3px;
    font-size: 0.85rem;
    text-transform: capitalize;
}

.p-tag-success {
    background: #28a745 !important;
    color: #fff !important;
}
.p-tag-warning {
    background: #ffc107 !important;
    color: #212529 !important;
}
.p-tag-info {
    background: #17a2b8 !important;
    color: #fff !important;
}
.p-tag-danger {
    background: #dc3545 !important;
    color: #fff !important;
}

/* Dialog and Form Fields */
.p-dialog .p-dialog-header {
    background: #f1f1f1;
    border-bottom: 1px solid #ddd;
    color: #333;
    font-weight: 600;
}

.p-dialog .p-dialog-content {
    padding: 1.5rem;
}

.p-dialog .p-field label {
    margin-bottom: 0.5rem;
    display: inline-block;
    font-weight: 500;
    color: #555;
}

.p-dialog .p-field input,
.p-dialog .p-field .p-dropdown {
    width: 100%;
    margin-bottom: 1rem;
}

.p-dialog .p-dialog-footer {
    background: #f1f1f1;
    border-top: 1px solid #ddd;
    padding: 0.75rem 1rem;
    text-align: right;
}

/* Buttons */
.p-button-success {
    background: #28a745 !important;
    border: none;
}

.p-button-danger {
    background: #dc3545 !important;
    border: none;
}

.p-button-text {
    color: #007bff !important;
    background: transparent !important;
    border: none;
}

.p-button-text:hover {
    color: #0056b3 !important;
}

/* Confirmation dialog icons */
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #333;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .content-area {
        padding: 1rem;
    }

    .p-datatable .p-datatable-header, 
    .p-datatable .p-datatable-footer {
        padding: 0.5rem;
    }
}

