
.topnav-menubar {
    border-radius: 0;
    border: none;
    background: #1d1d1d;
}

/* Logo styling */
.topnav-logo {
    height: 60px;
    padding-left: 10px;
    padding-top: 5px;
    /* Adjust as needed */
}

/* Username styling */
.topnav-username {
    color: #ffffff;
    font-weight: 500;
    margin-right: 1rem;
}

/* Avatar styling */
.topnav-avatar {
    background-color: #2196F3 !important; /* Blue background */
    color: #ffffff !important;
    font-weight: bold;
    cursor: pointer;
}

/* Menu overrides */
.p-tieredmenu {
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #ffffff;
}

.p-tieredmenu .p-menuitem-link {
    color: #333;
}

/* Hover state for menu items */
.p-tieredmenu .p-menuitem-link:hover {
    background: #f1f1f1;
}

/* Adjust font sizes for a cleaner look */
.p-menubar, .p-menubar-start, .p-menubar-end {
    font-size: 0.95rem;
}
