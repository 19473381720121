/* src/Pages/Admin/UserAdminPanel.css */
.content-column {
    background: #f8f9fa;
}

.content-area {
    background: #fff;
    border-radius: 4px;
    padding: 1.5rem;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}
